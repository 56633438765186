export const getOverviewData = () => {
  return [
    {
      sectionImage: "Overview/yaamava-logo.svg",
      sectionTitle: "Shift4",
      paragraph:
        "eaturing stunning views to Red Rock National Park, this intimate event space is ideal for meeting and receptions.",
      // area: '44,500',
      reception: "120",
      banquet: "100",
    },
  ];
};

export const getCarouselImages = () => {
  return [];
};

export const getLayoutImages = () => {
  return ["yaamava/Layout/layout-yamaava-club.jpeg"];
};

export const getEventPhotosImages = () => {
  return [
    "Shift4/EventPhotos/s4-yaa-1.jpg",
    "Shift4/EventPhotos/s4-yaa-2.jpg",
    "Shift4/EventPhotos/s4-yaa-3.jpg",
    "Shift4/EventPhotos/s4-yaa-4.jpg",
  ];
};
